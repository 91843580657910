/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var home_block = $('.home-block .content'),
            block_height = [],
            maxHeigh = 0;
        for(i = 0; i < home_block.length; i++) {
          block_height.push( $(home_block[i]).height() );
        }
        maxHeigh = Math.max.apply(Math, block_height);
        $.each(home_block, function(i) {
          $(home_block[i]).height(maxHeigh);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'contact_us': {
      init: function() {
        function init_map() {
          var var_location = new google.maps.LatLng(-6.3918266,106.9092141),
              var_mapoptions = {
                center: var_location,
                zoom: 14
              },
              var_map = new google.maps.Map(document.getElementById('map-container'), var_mapoptions);
              var_marker = new google.maps.Marker({
                position: var_location,
                map: var_map,
                title: 'PT. Persada Medika Solusindo'
              });
          var_marker.setMap(var_map); 
        }
        google.maps.event.addDomListener(window, 'load', init_map);
      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
